<ng-container *ngIf="sections$ | async as sections">
  <div class="section-icon-with-progress text-center" *ngIf="showProgress && !!sections" automationid="Page_ApplicationNav">
    <div class="expand-indicator-arrow" *ngIf="showExpandArrow">
      <i class="fa fa-chevron-down color-primary"></i>
    </div>
    <div class="progress-container" [style.width.px]="progressRadius * 2" [style.height.px]="progressRadius * 2">
      <canvas #progressCanvas
        class="progress-canvas"
        width="{{ this.progressRadius * 2 }}"
        height="{{ this.progressRadius * 2 }}">
      </canvas>
      <div class="section-icon {{ activeSectionName | textCase: 'kebab' }}"></div>
    </div>
  </div>

  <p *ngIf="!isProd">
    <strong *ngIf="isURLA2020()">URLA 2020</strong>
  </p>

  <ul class="application-nav" aria-describedby="step-info" *ngIf="showList && !!sections" automationid="Section_ApplicationNavBar">
    <ng-container *ngFor="let section of sections">
      <li *ngIf="sectionState && sectionState[section.sectionId]"
        class=" {{ 'section-' + section.sectionId }}"
        [attr.aria-current]="sectionState[section.sectionId].isActive ? 'step' : ''"
        [ngClass]="{
          active: sectionState[section.sectionId].isActive,
          complete: sectionState[section.sectionId].isComplete
        }"
      >
        <ng-container *ngIf="section.sectionId !== 'review'">
          <ng-container *ngIf="isSectionAccessible(section) else disabled">
            <a
              aria-current="step"
              [tabindex]="isSectionNavOpen ? '0' : '-1'"
              [attr.automationid]="'ANavBar_' + section.title"
              (click)="setActiveSection(section)"
              (keydown.enter)="setActiveSection(section)"
            >
              {{ section.title }}
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="section.sectionId === 'review'">
          <a *ngIf="canSubmit; else disabled"
            aria-current="step"
            [tabindex]="isSectionNavOpen ? '0' : '-1'"
            [routerLink]="['/', 'application', section.sectionId, 'final']"
          >
            {{ section.title }}
          </a>
        </ng-container>
        <ng-template #disabled>
          <span>{{ section.title }}</span>
        </ng-template>
        <span class="sr-only" *ngIf="sectionState[section.sectionId].isComplete">Step Completed</span>
      </li>
    </ng-container>
  </ul>
  <!--sections -->
  <span id="step-info-{{ stepCurrent }}-{{ uniqueId }}" class="sr-only">Currently on step {{ stepCurrent }} of {{ sections.length }} steps</span>
</ng-container>
