import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '$api';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UIStoreService } from '$ui';
import { LoanUtils, MilestoneStateEnum } from '../../../../shared/utils/loan-utils';
import { AppSettings, AnalyticsService, AuthService } from '$shared';
import { flatMap, first, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeartbeatService } from 'src/app/shared/services/heartbeat.service';
import { environment } from '$env';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UrlaChangeModalComponent } from 'src/app/components/modals/urla-change/urla-change-modal.component';
import { CPOSClientLogLevelEnum, ICPOSAppState, ICPOSLogEntry, ILoanViewModel, URLAFormTypeEnum, SingleUserLockTypeEnum  } from 'src/app/shared/models';
import { ModalsService } from '$modals';


const cloneDeep = require('lodash/cloneDeep');

@UntilDestroy()
@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  public config$ = this.ui.select.config$;
  public appState$ = this.api.appState$;
  public megaLoan$ = this.api.select.megaLoan$;
  private milestoneStateSub: Subscription;

  // public loanCount: number;

  public appState: ICPOSAppState;
  protected sub: Subscription;
  /** Used in UI to highlight current step */
  public milestoneState: MilestoneStateEnum;
  /** Used in UI to compare */
  public milestoneStateEnum = MilestoneStateEnum;

  public refreshLoanLockSubsciption: Subscription;

  public isProd = environment.production;

  constructor(
    private authService: AuthService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public ui: UIStoreService,
    public settings: AppSettings,
    private analytics: AnalyticsService,
    private heartbeatService: HeartbeatService,
    private modals: ModalsService,
    private log: LoggingService,
    public dialog: MatDialog
  ) { }

  public ngOnInit() {
    // Check if a loan ID has been selected before loading the loan
    if (this.settings.loanId) {
      this.api.megaLoan.get(false, true)
      .pipe(untilDestroyed(this))
      .pipe(first())
      .subscribe((loan) => {
        this.api.appState.get()
          .pipe(untilDestroyed(this))
          .pipe(first())
          .subscribe((appState) => {
            const isLockedByOtherBorrower = LoanUtils.isLockedByOtherBorrower(loan, this.settings);
            if (isLockedByOtherBorrower) {
              this.openLoanLockModalComponent(loan);
            } else if (LoanUtils.isLockedExclusivelyByCurrentUser(loan, this.settings)) {
              this.refreshLoanLockSubsciption = this.heartbeatService.handleLoanRefresh();
            } else if (LoanUtils.isLockedLimitActionsByCurrentUser(loan, this.settings)) {
              const milestoneState = LoanUtils.getMilestoneState(loan.currentMilestone, appState);
              // Keep Limited Actions lock if user is accessing Dashboard view
              if (milestoneState !== MilestoneStateEnum.Application || this.isDashboardRedirectEnabled(this.milestoneState)) {
                this.refreshLoanLockSubsciption = this.heartbeatService.handleLoanRefresh(SingleUserLockTypeEnum.LimitedActions);
              } else {
                this.openLoanLockModalComponent(loan);
              }
            } else if (!loan.loanAccessByUserId && this.settings.userId) {
              // Loan was just created and does not have a userId assigned
              loan.loanAccessByUserId = +this.settings.userId;
              this.refreshLoanLockSubsciption = this.heartbeatService.handleLoanRefresh();
            }
            this.api.appState.get().subscribe();

            // We don't want to log opening newly created loans.
            if (!LoanUtils.isBaseLoan(loan)) {
              this.api.auditLog.borrowerOpenedLoan(this.settings.loanId);
            }

            this.api.client1003Config.get().subscribe();

            this.milestoneStateSub = this.api.getApiStoreData(this.megaLoan$)
              .pipe(withLatestFrom(this.appState$))
              .pipe(untilDestroyed(this))
              .subscribe(([loan, appState]) => {
                this.milestoneState = LoanUtils.getMilestoneState(loan.currentMilestone, appState);
                if (environment.endpoints.logging) {
                  this.log.track<ICPOSLogEntry>({
                    eventTime: new Date(),
                    logLevel: CPOSClientLogLevelEnum.Info,
                    message: `LoanId: ${this.settings.loanId}. Page: home.component.ts: User ${this.settings.userId}. Subject Property Address => ${LoanUtils.getSubjectProperty(loan).streetName}`,
                    metadata: {
                      routePath: this.router.url
                    },
                  });
                }
                this.handleRedirects(this.milestoneState);
                // Check if the URLA version has changed
                if (
                  loan.originalURLAFormType !== loan.urlaFormType &&
                  appState &&
                  appState.loaded &&
                  this.milestoneState == 1 &&
                  appState.form1003.state.some(s => s.isComplete == false) &&
                  !appState.form1003.urlaModalShown
                ) {
                  this.dialog.open(UrlaChangeModalComponent, { disableClose: true });
                }
            });
          });
      });
    } else {
      // Otherwise, re-route borrower to the loan selection page
      this.router.navigate(['/my-loans'], {queryParamsHandling: 'merge'});
    }

    // Get company profile, needed by analytics
    this.api.broker.get().pipe(
      flatMap(broker => {
        return this.api.currentCompanyProfile.get(broker.leadSourceId, this.settings.userId);
      }),
    );
    // Load guids used to generate new entities
    this.api.services.guidsGet().subscribe(res => (this.api.guids = res));

    this.sub = this.appState$.pipe(untilDestroyed(this)).subscribe(appState => (this.appState = appState));

  }

  public isDashboardRedirectEnabled(milestoneState: MilestoneStateEnum) {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams['secureLinkId'] === '5' || (milestoneState === MilestoneStateEnum.Documentation && queryParams['token']);
  }

  /**
   * Handles redirecting when query parameters were provided that instruct the
   * app to go to a certain route or perform a specific function (e.g. tasks)
   */
  public handleRedirects(milestoneState: MilestoneStateEnum) {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['secureLinkId'] === '5' || queryParams['secureLinkId'] === '7' || milestoneState === MilestoneStateEnum.Documentation && queryParams['token']) {
      // SecureLink 5 is "View BNL"; 7 is "Sign Initial Disclosures"
      this.router.navigate(['/tasks'], { queryParamsHandling: "preserve" });
    }
  }

  /**
   * Manage app state changes, from within a section and from section to section
   * @param section
   */
  public appStateChange(section: 'form1003' | 'dashboard', megaLoan?: ILoanViewModel) {
    // New Ref
    const appState: ICPOSAppState = cloneDeep(this.appState);

    const action = () => {
      // Which section
      switch (section) {
        case 'form1003':
          // Fire appropriate analytics event
          if (!appState.form1003.isStarted) {
            this.analytics.trackEvent('Loan App Started BC', {});
          } else {
            this.analytics.trackEvent('Loan App Continue BC', {});
          }

          // Set default routing
          let routeNext = 'loan-purpose';
          let pageNext = '';
          // Filter for sections that are not complete
          const notComplete = appState.form1003.state.filter(sec => !sec.isComplete);
          // If result, update last page and section Id
          if (notComplete.length) {
            routeNext = notComplete[0].sectionId;
            pageNext = notComplete[0].lastPageId || '';
          }
          // If non spouse coborrower, default to personal if loan purpose is set
          if (
            megaLoan &&
            megaLoan.transactionInfo &&
            megaLoan.transactionInfo.loanApplications.length &&
            megaLoan.transactionInfo.loanApplications[0].isPrimary === false
          ) {
            if (routeNext === 'loan-purpose') {
              routeNext = 'personal';
              pageNext = 'start';
            }
            // Check if state is present
            // If so, always set loan purpose to complete and last page to summary
            if (appState.form1003.state.length) {
              // Set loan purpose to complete so that summary is always the default
              appState.form1003.state.forEach(sectionState => {
                if (sectionState.sectionId === 'loan-purpose') {
                  sectionState.isComplete = true;
                  this.api.reporting.updateConversionFunnel(sectionState.title).subscribe();
                  sectionState.lastPageId = 'summary';
                }
              });
            } else {
              // Appstate has not been generated yet, create an initial one for loan purpose to set summary and complete flags
              appState.form1003.state.push(<any>{
                sectionId: 'loan-purpose',
                lastPageId: 'summary',
                isComplete: true,
                isActive: false,
                hasSummary: null,
                title: null,
              });
            }
          }
          this.router.navigate(['/application/', routeNext, pageNext]);
          break;
        case 'dashboard':
          this.analytics.trackEvent('Dashboard BC', {});
          this.router.navigate(['/tasks'], {queryParamsHandling: 'merge'});
          appState[section].isStarted = true;
          break;
      }
    };

    // Always set is started prop
    //

    // console.log('appStateChange', appState)
    this.api.appState.set(appState).subscribe(() => action(), () => action());
  }

  /***/
  public appStateClear() {
    this.api.appState
      .set({
        loaded: true,
        form1003: {
          isActive: true,
          isStarted: null,
          completedDate: null,
          state: [],
        },
        dashboard: {
          isActive: null,
          isStarted: null,
          completedDate: null,
        },
      })
      .subscribe();
  }

  public isURLA2020(): boolean {
    return LoanUtils.isURLA2020(this.settings.urla);
  }

  /**
   *
   * @param lsid
   */
  public configChange() {
    this.settings.urla = URLAFormTypeEnum.URLA2020;
    this.api.client1003Config.get().subscribe(() => { });
  }

  private openLoanLockModalComponent(loan: ILoanViewModel) : MatDialogRef<any,any> {
    let dialogRef = this.modals.open('LoanLockModalComponent', false, 'lg', {
      loanAccessByBorrowerFullName: LoanUtils.getLoanAccessByBorrowerFullName(loan),
    }, null, { disableClose: true });
    dialogRef.afterClosed()
    .subscribe(() => {
      if (this.settings.secureLinkId !== null && this.settings.secureLinkId !== undefined) {
        this.authService.logOut();
      } else {
        this.router.navigate(['/my-loans']);
      }
    });
    return dialogRef;
}

  // Must be present even if not used for unsubs
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.milestoneStateSub.unsubscribe();
    if (this.refreshLoanLockSubsciption) {
      this.refreshLoanLockSubsciption.unsubscribe();
    }
  }
}
