import { DocumentsUtils } from '../../routes/dashboard/shared/utils/documents-utils';
import {
  ILoanViewModel,
  IBorrowerViewModel,
  IPhoneViewModel,
  IBorrowerDocumentViewModel,
  IPropertyViewModel,
  ILoanApplicationViewModel,
  IEmploymentInfoViewModel,
  IIncomeInfoViewModel,
  IBaseLoanViewModel,
  BorrowerContextTypeEnum,
  ESignerRoleEnum,
  MilestoneStatusTypeEnum,
  MortgageTypeEnum,
  LoanChannelTypeEnum,
  LoanPurposeTypeEnum,
  EmploymentTypeEnum,
  BankStatementsIncomeTypeEnum,
  IncomeTypeEnum,
  URLAFormTypeEnum,
  ICPOSAppState,
  ConsentStatusEnum,
  SecureLinkEnum

} from 'src/app/shared/models';
import { propertyIsDefined, ValueTypeRule, propertyHasValue } from './data-validation-util';
import { AppSettings } from '$shared';
import { Params } from '@angular/router';

export enum MilestoneStateEnum {
  Application = 1,
  Documentation = 2,
  Closing = 3,
  Inactive = 4,
}

export enum ProcessingStateEnum {
  Preprocessing = 1,
  Postprocessing = 2,
}

export enum CSAppraisalEnabledEnum {
  Unknown = 0,
  Conventional = 1,
  FHA = 2,
  ConventionalJumbo = 4,
  ConventionalSuperJumbo = 8,
  VA = 16,
  USDA = 32
}

export function isSpouseOnTheLoan(loan: ILoanViewModel): boolean {
  return !!loan
    && !!loan.transactionInfo
    && !!loan.transactionInfo.loanApplications
    && !!loan.transactionInfo.loanApplications.length
    && loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan;
}
export function hasCoBorrower(loan: ILoanViewModel): boolean {
  return isSpouseOnTheLoan(loan);
}
export function getBorrowers(loan: ILoanViewModel): IBorrowerViewModel[] {
  return loan
    && loan.transactionInfo
    && loan.transactionInfo.borrowers
    || [];
}
export function getBorrower(loan: ILoanViewModel): IBorrowerViewModel {
  return getBorrowers(loan)[0];
}
export function getCoBorrower(loan: ILoanViewModel): IBorrowerViewModel {
  return hasCoBorrower(loan) ? getBorrowers(loan)[1] : null;
}
export function isJointAccount(loan: ILoanViewModel): boolean {
  const borrower = getBorrower(loan);
  const coBorrower = getCoBorrower(loan);
  return borrower && borrower.userAccount && borrower.userAccount.userAccountId
    && coBorrower && coBorrower.userAccount && coBorrower.userAccount.userAccountId
    && borrower.userAccount.userAccountId !== coBorrower.userAccount.userAccountId
    && (coBorrower.userAccount.isOnlineUser !== true || borrower.userAccount.username === coBorrower.userAccount.username);
       // if isOnlineUser is true AND the borr/coborr have different email => coborrower has their own account
}
export function isCoBorrowerAccountOffered(loan: ILoanViewModel, loanApplicationId: string): boolean {
  const borrowersFilteredByLoanAppId = LoanUtils.getBorrowersByLoanApplicationId(
    loan,
    loanApplicationId,
  );

  const coborrower = borrowersFilteredByLoanAppId[1];

  // null - not offered
  // false/true - offered
  return coborrower && coborrower.borrowerDetail && coborrower.borrowerDetail.welcomeEmailRequired !== null;
}

export function didSpouseProvideSecurityAnswer(loan: ILoanViewModel): boolean {
  const [, { userAccount: { securityAnswer } }] = loan.transactionInfo.borrowers;

  return securityAnswer !== null && securityAnswer !== '';
}

export function isCoBorrowerJointAccount(loan: ILoanViewModel, loanApplicationId: string): boolean {
  const borrowersFilteredByLoanAppId = LoanUtils.getBorrowersByLoanApplicationId(
    loan,
    loanApplicationId,
  );

  const coborrower = borrowersFilteredByLoanAppId[1];

  return coborrower && coborrower.userAccount && coborrower.userAccount.jointAccountId !== null;
}

export function setCoBorrowerJointAccount(loan: ILoanViewModel, loanApplicationId: string): void {
  const borrowerRegistrationOffered = isCoBorrowerAccountOffered(loan, loanApplicationId);
  if (!borrowerRegistrationOffered) {
    const [borrowerPrimary, borrowerSecondary] = loan.transactionInfo.borrowers;
    if (borrowerPrimary.email === borrowerSecondary.email) {
      borrowerPrimary.userAccount.jointAccountId = borrowerSecondary.userAccount.userAccountId;
      borrowerSecondary.userAccount.jointAccountId = borrowerPrimary.userAccount.userAccountId;

      borrowerSecondary.borrowerDetail.welcomeEmailRequired = false;
    }
  }
}

export function setCoBorrowerSplitAccount(loan: ILoanViewModel, loanApplicationId: string): void {
  const borrowerRegistrationOffered = isCoBorrowerAccountOffered(loan, loanApplicationId);
  if (!borrowerRegistrationOffered) {
    const [borrowerPrimary, borrowerSecondary] = loan.transactionInfo.borrowers;
    if (borrowerPrimary.email !== borrowerSecondary.email) {
      borrowerPrimary.userAccount.jointAccountId = null;
      borrowerSecondary.userAccount.jointAccountId = null;
    }
  }
}


export class LoanUtils {

  static isSpouseOnTheLoan(loan: ILoanViewModel): boolean {
    return isSpouseOnTheLoan(loan);
  }

  static hasCoBorrower(loan: ILoanViewModel): boolean {
    return hasCoBorrower(loan);
  }

  static getBorrowers(loan: ILoanViewModel): IBorrowerViewModel[] {
    return getBorrowers(loan);
  }

  static getBorrower(loan: ILoanViewModel): IBorrowerViewModel {
    return getBorrower(loan);
  }

  static getCoBorrower(loan: ILoanViewModel): IBorrowerViewModel {
    return getCoBorrower(loan);
  }

  static isJointAccount(loan: ILoanViewModel): boolean {
    return isJointAccount(loan);
  }

  static externaleSignRequired(loan: ILoanViewModel, loggedInUserId: number): boolean {
    const borrowers = this.getBorrowersByCurrentUserId(loan, loggedInUserId);
    const disclosureTrackingsSorted = loan.disclosureTrackingLists?.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());

    return loan.financialInfo && !loan.financialInfo.mustDiscloseInIMP && disclosureTrackingsSorted?.length > 0 && !!borrowers.find(x => x.borrowerToLoanBindingInfoes && disclosureTrackingsSorted[0].disclosureTrackingMetadatas.find(y => y.borrowerId == x.borrowerId && !!y.eDisclosurePackageId && !y.disclosureSignedDate));
  }

  static externaleSignRequiredForBorrower(loan: ILoanViewModel): boolean {
    const borrower = this.getBorrower(loan);
    const disclosureTrackingsSorted = loan.disclosureTrackingLists?.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());

    return borrower.borrowerToLoanBindingInfoes && disclosureTrackingsSorted?.length > 0 && !!disclosureTrackingsSorted[0].disclosureTrackingMetadatas.find(x => x.borrowerId == borrower.borrowerId && !!x.eDisclosurePackageId && !x.disclosureSignedDate);
  }

  static externaleSignRequiredForCoBorrower(loan: ILoanViewModel): boolean {
    const coBorrower = this.getCoBorrower(loan);
    const disclosureTrackingsSorted = loan.disclosureTrackingLists?.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());

    return coBorrower.borrowerToLoanBindingInfoes && disclosureTrackingsSorted?.length > 0 && !!disclosureTrackingsSorted[0].disclosureTrackingMetadatas.find(x => x.borrowerId == coBorrower.borrowerId && !!x.eDisclosurePackageId && !x.disclosureSignedDate);
  }

  static getPreferredPhoneNumber(borrower: IBorrowerViewModel): IPhoneViewModel {
    if (!(borrower && borrower.phones && borrower.phones.length)) return null;
    const preferredNumbers = borrower.phones.filter((phoneNumber: any) => phoneNumber.isPreferred);
    return preferredNumbers && preferredNumbers.length ? preferredNumbers[0] : null;
  }

  static getBorrowerIdByUserAccountId(loan: ILoanViewModel, userAccountId: number | string): string | null {
    const userAccountIdMap: Record<number, string> = LoanUtils.getBorrowers(loan)
      .filter(borrower => {
        return borrower.userAccount && !!borrower.userAccount.userAccountId;
      })
      .reduce((currentValue, borrower) => {
        currentValue[borrower.userAccount.userAccountId] = borrower.borrowerId;
        return currentValue;
      }, <Record<number, string>>{});

    return userAccountIdMap[+userAccountId] || null;
  }

  static isPinAuthRequired(loan: ILoanViewModel, document: IBorrowerDocumentViewModel, loggedInUserId: number): boolean {
    return LoanUtils.isJointAccount(loan) && (document && LoanUtils.eSignRequired(loan, document, loggedInUserId) || LoanUtils.externaleSignRequired(loan, loggedInUserId));
  }

  static eSignRequired(loan: ILoanViewModel, document: IBorrowerDocumentViewModel, loggedInUserId: number): boolean {
    return DocumentsUtils.areThereDocumentsToSign(document, LoanUtils.getLoggedInUserEnum(loan, loggedInUserId));
  }

  static eDiscloseReviewRequired(loan: ILoanViewModel, document: IBorrowerDocumentViewModel, loggedInUserId: number): boolean {
    return DocumentsUtils.areThereDocumentsToReview(document, LoanUtils.getLoggedInUserEnum(loan, loggedInUserId));
  }

  static getLoggedInUserEnum(loan: ILoanViewModel, loggedInUserId: number): BorrowerContextTypeEnum {
    if (LoanUtils.isJointAccount(loan)) {
      return BorrowerContextTypeEnum.Both;
    }
    const coBorrower = LoanUtils.getCoBorrower(loan);
    return loan
      && coBorrower
      && coBorrower.userAccount
      && coBorrower.userAccount.userAccountId === loggedInUserId
      ? BorrowerContextTypeEnum.CoBorrower
      : BorrowerContextTypeEnum.Borrower;
  }

  /**
   * Based on the currently logged in user, get a list of borrowers that should
   * be considered when eConsent status values are checked
   * @param loan Current loan object
   * @param loggedInUserId User ID for the currently logged in borrower
   */
  static getBorrowersByCurrentUserId(loan: ILoanViewModel, loggedInUserId: number): IBorrowerViewModel[] {
    const borrowers = [];
    const borrower = LoanUtils.getBorrower(loan);
    const coBorrower = LoanUtils.getCoBorrower(loan);
    const loggedInUser = LoanUtils.getLoggedInUserEnum(loan, loggedInUserId);
    switch (loggedInUser) {
      case BorrowerContextTypeEnum.Both:
        borrowers.push(borrower);
        borrowers.push(coBorrower);
        break;
      case BorrowerContextTypeEnum.Borrower:
        borrowers.push(borrower);
        break;
      case BorrowerContextTypeEnum.CoBorrower:
        borrowers.push(coBorrower);
        break;
      default:
        // We should hopefully never run into this issue
        throw new Error(`No borrowers were found on the loan.`);
    }

    return borrowers;
  }



  /**
   * When cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent config is on,
   * We use this function to determine the number of borrowers on a 1003 that have
   * econsented
   * @param loan
   */
  static getBorrowersEconsented(loan: ILoanViewModel): IBorrowerViewModel[] {
    const borrowers = [];
    const borrower = LoanUtils.getBorrower(loan);
    const coBorrower = LoanUtils.getCoBorrower(loan);
    if (borrower && (borrower.eConsent.consentStatus == ConsentStatusEnum.Accept)) {
      borrowers.push(borrower);
    }

    if (coBorrower && (coBorrower.eConsent.consentStatus == ConsentStatusEnum.Accept)) {
      borrowers.push(coBorrower);
    }

    return borrowers;
  }

  /**
   * Copied from existing consumer app
   * @param loan
   * @param loggedInUserId
   * @param document
   */
  static getNextBorrowerESignerRoleForDocument(
    loan: ILoanViewModel,
    loggedInUserId: number,
    document: IBorrowerDocumentViewModel
  ) {

    const loggedInUser = LoanUtils.getLoggedInUserEnum(loan, loggedInUserId);

    // Borrower is logged in but has not accepted
    if (loggedInUser === BorrowerContextTypeEnum.Borrower && !document.borrowerAcceptId) {
      return ESignerRoleEnum.Borrower;
    }

    // Coborrower is logged in but has not accepted
    if (loggedInUser === BorrowerContextTypeEnum.CoBorrower && !document.coBorrowerAcceptId) {
      return ESignerRoleEnum.CoBorrower;
    }

    // Both are logged in
    if (loggedInUser === BorrowerContextTypeEnum.Both) {
      if (!document.borrowerAcceptId) {
        // Borrower has not accepted
        return ESignerRoleEnum.Borrower;
      } else if (!document.coBorrowerAcceptId) {
        // Borrower has accepted, but coborrower has not
        return ESignerRoleEnum.CoBorrower;
      }
    }

    // Catch all
    return null;
  }

  static getMilestoneState(milestone: MilestoneStatusTypeEnum, appState: ICPOSAppState = null): MilestoneStateEnum {
    if (!milestone) return MilestoneStateEnum.Application;
    switch (milestone) {
      case MilestoneStatusTypeEnum.Prospect:
      case MilestoneStatusTypeEnum.PreApproved:
        if (appState && appState.form1003 && !!appState.form1003.completedDate) {
          return MilestoneStateEnum.Documentation;
        } else {
          return MilestoneStateEnum.Application;
        }
      case MilestoneStatusTypeEnum.Incomplete:
      case MilestoneStatusTypeEnum.AppCompleted:
      case MilestoneStatusTypeEnum.Processing:
      case MilestoneStatusTypeEnum.Underwriting:
        return MilestoneStateEnum.Documentation;
      case MilestoneStatusTypeEnum.Approved:
      case MilestoneStatusTypeEnum.DocsOut:
      case MilestoneStatusTypeEnum.Funded:
      case MilestoneStatusTypeEnum.Completed:
        return MilestoneStateEnum.Closing;
      case MilestoneStatusTypeEnum.Adverse:
      case MilestoneStatusTypeEnum.Cancelled:
        return MilestoneStateEnum.Inactive;
      // case MilestoneStatusTypeEnum.Closed:
      // case MilestoneStatusTypeEnum.Registered:
      // case MilestoneStatusTypeEnum.Rejected:
      // case MilestoneStatusTypeEnum.Submitted:
      // case MilestoneStatusTypeEnum.Unsubmitted:
      default:
        console.warn(`Could not find a match to map MilestoneStatusTypeEnum:`, milestone);
        return MilestoneStateEnum.Application;
    }
  }

  static getProcessingState(milestone: MilestoneStatusTypeEnum): ProcessingStateEnum {
    if (!milestone) return ProcessingStateEnum.Preprocessing;
    switch (milestone) {
      case MilestoneStatusTypeEnum.Prospect:
      case MilestoneStatusTypeEnum.PreApproved:
      case MilestoneStatusTypeEnum.Incomplete:
      case MilestoneStatusTypeEnum.AppCompleted:
        return ProcessingStateEnum.Preprocessing;
      default:
        return ProcessingStateEnum.Postprocessing;
    }
  }

  static getCurrentAddress(loan: ILoanViewModel, borrower: IBorrowerViewModel): IPropertyViewModel {
    if (!(
      borrower
      && borrower.currentAddressId
      && loan
      && loan.transactionInfo
      && loan.transactionInfo.properties
      && loan.transactionInfo.properties.length
    )) return null;
    const allCurrentAddresses = loan.transactionInfo.properties.filter((property: any) => property.propertyId === borrower.currentAddressId);
    if (allCurrentAddresses && allCurrentAddresses.length) {
      return allCurrentAddresses[0];
    }
    return null;
  }

  static getSubjectProperty(loan: ILoanViewModel): IPropertyViewModel {
    if (!(loan
      && loan.transactionInfo
      && loan.transactionInfo.properties
      && loan.transactionInfo.properties.length)
    ) {
      return null;
    }
    const allSubjectProperties = loan.transactionInfo.properties.filter((property: any) => property.isSubjectProperty);
    if (allSubjectProperties && allSubjectProperties.length) {
      return allSubjectProperties[0];
    }
    return null;
  }

  static getPreApproval(subjectProperty: IPropertyViewModel): boolean | null {
    return subjectProperty && subjectProperty.needPreApproval != null ? subjectProperty.needPreApproval : null;
  }

  // non-subject property - address on the Credit tab is without county
  static isSubjectPropertyValid(loan: ILoanViewModel, validateCounty = true): boolean {

    const subjectProperty = LoanUtils.getSubjectProperty(loan);
    if (!subjectProperty) return false;

    let isValid = true;

    if (validateCounty) {
      isValid = !!subjectProperty.countyName;
    }

    return isValid
      && !!subjectProperty.cityName
      && !!subjectProperty.stateName
      && !!subjectProperty.streetName
      && !!subjectProperty.zipCode;
  }

  static getAppraisalEnabledMapMortgageType(loan: ILoanViewModel): CSAppraisalEnabledEnum {

    // Check for property
    if (!(loan
      && loan.financialInfo
      && loan.financialInfo.mortgageType != null
    )) return null;

    switch (loan.financialInfo.mortgageType) {
      case MortgageTypeEnum.Conventional: {
        return CSAppraisalEnabledEnum.Conventional;
      }
      case MortgageTypeEnum.FHA: {
        return CSAppraisalEnabledEnum.FHA;
      }
      case MortgageTypeEnum.ConventionalJumbo: {
        return CSAppraisalEnabledEnum.ConventionalJumbo;
      }
      case MortgageTypeEnum.ConventionalSuperJumbo: {
        return CSAppraisalEnabledEnum.ConventionalSuperJumbo;
      }
      case MortgageTypeEnum.VA: {
        return CSAppraisalEnabledEnum.VA;
      }
      case MortgageTypeEnum.USDA: {
        return CSAppraisalEnabledEnum.USDA;
      }
      default:
        return CSAppraisalEnabledEnum.Unknown;
    }
  }

  static isWholesale(loan: ILoanViewModel): boolean {
    return loan.loanChannelType === LoanChannelTypeEnum.Wholesale;
  }

  // If we've collected five of the six pieces, and is GetPreApproved, validation will fail
  static areSixPiecesAcquiredForAllLoanApplications(loan: ILoanViewModel, allowMissingSubjectProperty = false): boolean {
    const loanApplications = LoanUtils.getLoanApplications(loan);

    if (!loanApplications || loanApplications.length === 0) {
      return false;
    } else if (!(LoanUtils.isPropertyPieceAcquired(loan, allowMissingSubjectProperty)
      && LoanUtils.isPersonalPieceAcquired(loan)
      && LoanUtils.isIncomePieceAcquired(loan))
    ) {
      // If any of the 6 pieces is not acquired, break.
      return false;
    }

    return true;
  }

  static getLoanApplications(loan: ILoanViewModel): ILoanApplicationViewModel[] {
    return loan
      && loan.transactionInfo
      && loan.transactionInfo.loanApplications
      || [];
  }

  /**
   * Validates if 1st and 2nd piece of 6 pieces is acquired.
   */
  static isPersonalPieceAcquired(loan: ILoanViewModel): boolean {
    // Check for name is SSN
    const validatePersonalPieceForBorrower = (borrower: IBorrowerViewModel): boolean => {
      return !!borrower.firstName && borrower.lastName && !!borrower.ssn;
    };
    let retVal = validatePersonalPieceForBorrower(LoanUtils.getBorrower(loan));
    const coBorrower = LoanUtils.getCoBorrower(loan);
    // Check co-borrower if found
    if (coBorrower && LoanUtils.isSpouseOnTheLoan(loan)) {
      retVal = retVal && validatePersonalPieceForBorrower(coBorrower);
    }
    return retVal;
  }

  /**
   * Validates if 3rd, 4th and 5th piece of 6 pieces is acquired.
   * @param loan
   * @param allowMissingSubjectProperty
   */
  static isPropertyPieceAcquired(loan: ILoanViewModel, allowMissingSubjectProperty = false): boolean {

    const subjectProperty = LoanUtils.getSubjectProperty(loan);

    // 3rd piece - subject property street.
    const is3rdPieceValid = (subjectProperty && LoanUtils.isSubjectPropertyValid(loan)) || allowMissingSubjectProperty;
    // 4th piece - loan amount.
    const is4thPieceValid = !!loan.loanAmount;
    // 5th piece - estimated value of subject property for refinance, or purchase price for purchase.
    const is5thPieceValid = (
      (loan.loanPurposeType === LoanPurposeTypeEnum.Refinance && subjectProperty && !!subjectProperty.currentEstimatedValue)
      || (loan.loanPurposeType === LoanPurposeTypeEnum.Purchase && subjectProperty && !!subjectProperty.purchasePrice));

    return is3rdPieceValid && is4thPieceValid && is5thPieceValid;
  }

  /**
   * Validates if 6th piece of 6 pieces is acquired.
   * @param loan
   */
  static isIncomePieceAcquired(loan: ILoanViewModel): boolean {
    let totalCombinedIncomeAmountBorrowerAndCoborrower = 0;
    const borrower = LoanUtils.getBorrower(loan);
    const coBorrower = LoanUtils.getCoBorrower(loan);

    // Validate for borrower
    let retVal = LoanUtils.validateIncomePieceForBorrower(loan, LoanUtils.getBorrower(loan));

    const borrowerCurrentEmployment = LoanUtils.getCurrentEmploymentInfo(loan, borrower);
    let borrowerOrCoborrowerIsUnemployed = borrowerCurrentEmployment
      && borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed;

    // Validate if a coborrower also exists on the loan
    if (coBorrower) {
      retVal = retVal && LoanUtils.validateIncomePieceForBorrower(loan, coBorrower);

      const coBorrowerCurrentEmployment = LoanUtils.getCurrentEmploymentInfo(loan, coBorrower);
      const coBorrowerIsUnemployed = coBorrowerCurrentEmployment
        && coBorrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed;
      borrowerOrCoborrowerIsUnemployed = borrowerOrCoborrowerIsUnemployed || coBorrowerIsUnemployed;
    }

    if (loan.incomeVerificationType === BankStatementsIncomeTypeEnum.FullDocumentation && borrowerOrCoborrowerIsUnemployed) {
      totalCombinedIncomeAmountBorrowerAndCoborrower = LoanUtils.getBorrowerTotalIncomeAmount(loan, borrower);
      totalCombinedIncomeAmountBorrowerAndCoborrower = !!coBorrower
        ? totalCombinedIncomeAmountBorrowerAndCoborrower + LoanUtils.getBorrowerTotalIncomeAmount(loan, coBorrower)
        : totalCombinedIncomeAmountBorrowerAndCoborrower;
      retVal = retVal && (totalCombinedIncomeAmountBorrowerAndCoborrower > 0);
    }

    return retVal;
  }

  static validateIncomePieceForBorrower(loan: ILoanViewModel, borrower: IBorrowerViewModel): boolean {

    const borrowerCurrentEmployment = LoanUtils.getCurrentEmploymentInfo(loan, borrower);

    const hasOtherIncomes = LoanUtils.getOtherIncomes(loan, borrower).filter(function (i) {
      return (!i.isRemoved && !!i.amount);
    }).length > 0;

    if (!borrowerCurrentEmployment) return false;

    const incomeInformation = LoanUtils.getIncomeInformation(loan, borrowerCurrentEmployment);
    const hasIncomeInformation = incomeInformation.length > 0;

    return hasIncomeInformation
      &&
      (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.ActiveMilitaryDuty
        && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.MilitaryBasePay)
        && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.MilitaryBasePay).amount > 0)

      || (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.SelfEmployed
        && (LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.SelfEmployedIncome)
          && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.SelfEmployedIncome).amount !== 0
          || LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions)
          && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions).amount > 0))

      || (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.SalariedEmployee
        && ((LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.BaseEmployment)
            && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.BaseEmployment).amount > 0)
          || (LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions)
            && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions).amount > 0)
        ))

      || ((borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.Retired) && hasOtherIncomes)
      || (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed);
  }

  static getCurrentEmploymentInfo(loan: ILoanViewModel, borrower: IBorrowerViewModel): IEmploymentInfoViewModel {
    const filtered = LoanUtils.getBorrowerEmploymentInfo(loan, borrower).filter(o => !o.isAdditional);
    return filtered && filtered.length ? filtered[0] : null;
  }

  static getBorrowerEmploymentInfo(loan: ILoanViewModel, borrower: IBorrowerViewModel): IEmploymentInfoViewModel[] {
    if (!(loan
      && loan.transactionInfo
      && loan.transactionInfo.employments
      && loan.transactionInfo.employments.length)) return [];
    return loan.transactionInfo.employments.filter((o: any) => !o.isRemoved && o.borrowerId === borrower.borrowerId);
  }

  static getBorrowerTotalIncomeAmount(loan: ILoanViewModel, borrower: IBorrowerViewModel): number {

    const employmentInfo: IEmploymentInfoViewModel = LoanUtils.getCurrentEmploymentInfo(loan, borrower);
    let totalIncomeAmount: number = employmentInfo.totalMonthlyAmount * 12;
    const otherIncomes: IIncomeInfoViewModel[] = this.getOtherIncomes(loan, borrower);

    otherIncomes.forEach(o => totalIncomeAmount += (o.calculatedMonthlyAmount * 12));

    return totalIncomeAmount;
  }

  static getIncomes(loan: ILoanViewModel): IIncomeInfoViewModel[] {
    return loan
      && loan.transactionInfo
      && loan.transactionInfo.incomes
      || [];
  }

  static getOtherIncomes(loan: ILoanViewModel, borrower: IBorrowerViewModel): IIncomeInfoViewModel[] {
    const incomes = LoanUtils.getIncomes(loan);
    return incomes.filter(income => {
      return income.borrowerId === borrower.borrowerId
        && !income.isRemoved
        && (!income.employmentInfoId || income.employmentInfoId === '00000000-0000-0000-0000-000000000000');
    });
  }

  static getIncomeInformation(loan: ILoanViewModel, employment: IEmploymentInfoViewModel): IIncomeInfoViewModel[] {
    const incomes = LoanUtils.getIncomes(loan);
    return incomes.filter(o => o.employmentInfoId === employment.employmentInfoId);
  }

  static incomeInfoByTypeId(incomes: IIncomeInfoViewModel[], typeId: IncomeTypeEnum): IIncomeInfoViewModel {
    const incomeFiltered = incomes.filter(i => i.incomeTypeId === typeId);
    return incomeFiltered.length > 0 ? incomeFiltered[0] : null;
  }

  static hasCreditRan(loan: ILoanViewModel): boolean {
    const borrower = LoanUtils.getBorrower(loan);
    if (!borrower) return false;

    return borrower.ficoScore
      && (!!borrower.ficoScore.decisionScore
      || !!borrower.ficoScore.equifax
      || !!borrower.ficoScore.experian
      || !!borrower.ficoScore.transunion);
  }

  static getBorrowersByLoanApplicationId(loan: ILoanViewModel, loanApplicationId: string): IBorrowerViewModel[] {
    const allBorrowers = LoanUtils.getBorrowers(loan);
    return allBorrowers.filter(borrower => {
      return borrower.loanApplicationId === loanApplicationId;
    });
  }

  static isBaseLoan(loan: IBaseLoanViewModel): boolean {
    return loan && loan.isBaseLoan;
  }

  static isLoanProductSelected(loan: ILoanViewModel) {
    return loan && loan.product && loan.product.ruCode != null && loan.product.ruCode !== '';
  }

  static isURLA2020(loanURLAFormType: URLAFormTypeEnum | null): boolean {
    return loanURLAFormType === URLAFormTypeEnum.URLA2020;
  }

  static isLoanPurposeTypePurchase(loan: ILoanViewModel) {
    return propertyIsDefined(loan, 'loanPurposeType') && loan.loanPurposeType === LoanPurposeTypeEnum.Purchase;
  }

  static isLoanRefiOrPurchase(loan: ILoanViewModel): LoanPurposeTypeEnum {
    return propertyHasValue(loan, 'loanPurposeType', ValueTypeRule.GreaterThanZero) && loan.loanPurposeType === LoanPurposeTypeEnum.Purchase ?
      LoanPurposeTypeEnum.Purchase : LoanPurposeTypeEnum.Refinance;
  }

  static isLockedByOtherBorrower(loan: ILoanViewModel, settings: AppSettings): boolean {
    // If the loan is locked by some other user
    if (!!loan.loanAccessByUserId && settings.userId !== loan.loanAccessByUserId.toString()) {
      // If loan is locked by borrower
      if (loan.loanAccessByBorrowerFullName && (loan.loanAccessByBorrowerFullName.length > 0)) {
        return true;
      }
    }
    return false;
  }

  static isLockedExclusivelyByOtherUser(loan: ILoanViewModel, settings: AppSettings): boolean {
    // If the loan is locked by some other user
    return !!loan.loanAccessByUserId && settings.userId !== loan.loanAccessByUserId.toString();
  }

  static isLockedExclusivelyByCurrentUser(loan: ILoanViewModel, settings: AppSettings): boolean {
    return loan.loanAccessByUserId && settings.userId == loan.loanAccessByUserId.toString();
  }

  static isLockedLimitActionsByCurrentUser(loan: ILoanViewModel, settings: AppSettings): boolean {
    return loan.limitedActionsLockUserAccountId && settings.userId == loan.limitedActionsLockUserAccountId.toString();
  }

  static getLoanAccessByBorrowerFullName(loan: ILoanViewModel): string {
    // If another borrower acquired Limited Actions lock, then the loan is locked exclusively by LO
    return loan.limitedActionsLockUserAccountId > 0 ? null : loan.loanAccessByBorrowerFullName;
  }

  static setSecureLinkId(queryParams: Params, settings: AppSettings): void {
    let secureLinkId = queryParams['secureLinkId'];
    if (!secureLinkId) {
      const token = queryParams['token'];
      if (!token) {
        return;
      }
      // If token is present, but secureLinkId is missing, then assign default value
      secureLinkId = SecureLinkEnum.Unknown.toString();
    }
    settings.secureLinkId = secureLinkId;
  }

}
