import { Pipe, PipeTransform } from '@angular/core';
import { MilitaryServiceTypeEnum, OwnershipStatusTypeEnum, MaritalStatusTypeEnum, DomesticRelationshipRightsEnum, PreferredLanguageTypeEnum, CounselingWorkshopTypeEnum, CounselingFormatTypeEnum, CounselingProviderTypeEnum } from '../models';

type EnumValue = string | number;
type MappingKey = string;
type Transform = (value: EnumValue) => string;

const mappings: Record<MappingKey, Transform> = {
  'MilitaryServiceTypeEnum': value => {
    switch (value) {
      case MilitaryServiceTypeEnum.Serving:
        return 'Active Duty';
      case MilitaryServiceTypeEnum.Retired:
        return 'Retired / Discharged';
      case MilitaryServiceTypeEnum.Reserve:
        return 'National Guard / Reserves';
      default:
        return null;
    }
  },
  'OwnershipStatusTypeEnum': value => {
    switch (value) {
      case OwnershipStatusTypeEnum.Own:
        return 'Own';
      case OwnershipStatusTypeEnum.Rent:
        return 'Rent';
      case OwnershipStatusTypeEnum.RentFree:
        return 'Living Rent Free';
      default:
        return null;
    }
  },
  'MaritalStatusTypeEnum': value => {
    switch (value) {
      case MaritalStatusTypeEnum.Married:
        return 'Married';
      case MaritalStatusTypeEnum.Separated:
        return 'Separated';
      case MaritalStatusTypeEnum.Unmarried:
        return 'Unmarried';
      case MaritalStatusTypeEnum.CivilUnion:
        return 'Civil Union';
      case MaritalStatusTypeEnum.DomesticPartnership:
        return 'Domestic Partnership';
      case MaritalStatusTypeEnum.RegisteredReciprocalBeneficiaryRelationship:
        return 'Registered Reciprocal Beneficiary Relationship';
      case MaritalStatusTypeEnum.Other:
        return 'Other';
      default:
        return null;
    }
  },
  'DomesticRelationshipRightsEnum': value => {
    switch (value) {
      case DomesticRelationshipRightsEnum.SelectOne:
      default:
        return 'I don\'t know';
      case DomesticRelationshipRightsEnum.YesThereIsARelationshipWithSomeoneWhoHasPropertyRights:
        return 'Yes';
      case DomesticRelationshipRightsEnum.NoNoOneElseHasPropertyRights:
        return 'No';
    }
  },
  'PreferredLanguageTypeEnum': value => {
    switch(value) {
      case PreferredLanguageTypeEnum.English:
        return 'English';
      case PreferredLanguageTypeEnum.Chinese:
        return 'Chinese';
      case PreferredLanguageTypeEnum.Korean:
        return 'Korean';
      case PreferredLanguageTypeEnum.Other:
        return 'Other';
      case PreferredLanguageTypeEnum.Spanish:
        return 'Spanish';
      case PreferredLanguageTypeEnum.Tagalog:
        return 'Tagalog';
      case PreferredLanguageTypeEnum.Vietnamese:
        return 'Vietnamese';
      default:
        return 'I do not wish to respond';
    }
  },
  'CounselingWorkshopTypeEnum': value => {
    switch(value) {
      case CounselingWorkshopTypeEnum.InPerson: 
        return 'Attended Workshop in Person';
      case CounselingWorkshopTypeEnum.WebBased:
        return 'Completed Web-Based Workshop';
      default:
        return 'None';
    }
  },
  'CounselingFormatTypeEnum': value => {
    switch(value) {
        case CounselingFormatTypeEnum.FaceToFace:
          return 'Face To Face';
        case CounselingFormatTypeEnum.Telephone:
          return 'Telephone';
        case CounselingFormatTypeEnum.Internet:
          return 'Internet';
        case CounselingFormatTypeEnum.Hybrid:
          return 'Hybrid';
        default:
          return '';
    }
  },
  'CounselingProviderTypeEnum': value => {
    switch(value) {
        case CounselingProviderTypeEnum.HudApprovedAgency:
          return 'HUD approved agency';
        case CounselingProviderTypeEnum.NotHudApprovedAgency:
          return 'Not a HUD approved agency';
        case CounselingProviderTypeEnum.None:
        default:
          return '';
    }
  }
};

@Pipe({
  name: 'enumToString',
})
export class EnumToStringPipe implements PipeTransform {
  transform(value: EnumValue, enumType: MappingKey): string {
    const transform = mappings[enumType];
    return transform ? transform(value) : `${value}`;
  }
}
